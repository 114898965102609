<div class="mat-headline-5 ant-grid__ticks">
  <ng-container *ngIf="elapsedTicks < gameLength else finishedTemplate">
    Ticks: {{ elapsedTicks | number }} / {{ gameLength | number }}
  </ng-container>
</div>
<canvas #canvas class="ant-grid__canvas mat-elevation-z2" [width]="canvasWidth" [height]="canvasHeight"
  [style.maxWidth]="canvasWidth + 'px'"
  [class.ant-grid__canvas--full-width]="fullWidth"
  [class.ant-grid__canvas--mobile-dialog-width]="mobileDialog">
</canvas>
<ng-template #finishedTemplate>
  Game Over
</ng-template>
