<mat-dialog-content>
  <div *ngIf="board$ | async as board else loadingTemplate" fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="start center">
    <cci-ant-grid class="test-ant-dialog__ant-grid"
      [animals]="board.animals"
      [elapsedTicks]="board.gameStatus.elapsedTicks"
      [gameLength]="board.gameStatus.gameLength"
      [mobileDialog]="isMobile$ | async"
      [grid]="board.grid">
    </cci-ant-grid>
    <cci-leaderboard [allowViewingCode]="false" [animals]="board.animals"></cci-leaderboard>
  </div>
</mat-dialog-content>

<ng-template #loadingTemplate>
  <div class="game" fxLayoutAlign="center center" [style.height]="'250px'"
  [style.width]="'250px'">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
