<div fxLayout="column" fxLayoutAlign="center center">
  <div class="mat-subheading-2">
    Leaderboard
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z2" matSort matSortActive="score" matSortDirection="desc">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let ant">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <div class="leaderboard__ant" [style.backgroundColor]="ant.color" [style.borderColor]="getBorderColor(ant)"></div>
          <div>{{ ant.name }}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="score">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Score</th>
      <td mat-cell *matCellDef="let ant"> {{ ant.score | number }} </td>
    </ng-container>

    <ng-container matColumnDef="errors">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Errors</th>
      <td mat-cell *matCellDef="let ant">
        <div *ngIf="ant.error" class="mat-error" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <mat-icon color="warn" [matTooltip]="ant.error">error</mat-icon>
          <div>{{ ant.error }}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="menu" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let ant">
        <button *ngIf="allowViewingCode" mat-icon-button (click)="viewCode(ant)">
          <mat-icon>code</mat-icon>
        </button>

        <button *ngIf="ant.error" color="warn" mat-icon-button (click)="showError(ant)">
          <mat-icon>error</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
