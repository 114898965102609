<div fxFlexFill fxLayout="column" *ngIf="ants$ | async as ants">
  <mat-toolbar color="primary" class="edit-ants__toolbar mat-elevation-z4">
    <button mat-icon-button routerLink="/home">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button mat-icon-button (click)="sideNav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <div fxFlex></div>
    <span>Ant Collection</span>
    <button mat-icon-button (click)="showRules()" matTooltip="Show the rules">
      <mat-icon>assignment</mat-icon>
    </button>
    <div fxFlex></div>
    <cci-theme-selector></cci-theme-selector>
    <button mat-icon-button (click)="logout()" matTooltip="Logout">
      <mat-icon>logout</mat-icon>
    </button>
  </mat-toolbar>


  <mat-sidenav-container fxFlex>
    <mat-sidenav #sideNav class="edit-ants__nav-list" [mode]="(isMobile$ | async) ? 'over' : 'side'" >
      <div fxFill fxLayout="column">
        <mat-toolbar class="edit-ants__side-nav-toolbar">
          <span fxFlex>Ants</span>
          <button mat-icon-button (click)="createAnt()" matTooltip="Add new ant">
            <mat-icon>add</mat-icon>
          </button>
        </mat-toolbar>

        <mat-nav-list  fxFlex>
          <mat-list-item *ngFor="let ant of ants" [routerLink]="[ant.username, ant.antName]" routerLinkActive="edit-ants__active-link" (click)="antClicked()">
            <mat-icon mat-list-icon>bug_report</mat-icon>
            <span matLine>{{ ant.antName }}</span>
            <mat-icon *ngIf="isReadonly(ant)" matTooltip="This bug was created by another user">lock</mat-icon>
          </mat-list-item>
        </mat-nav-list>
      </div>

    </mat-sidenav>
    <mat-sidenav-content>
      <div *ngIf="!route?.firstChild" fxFlexFill fxLayoutAlign="center center" class="mat-headline-4 edit-ants__select-ant-watermark">
        Please select an ant
      </div>
      <router-outlet #outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
