<div fxFlexFill fxLayout="column">
  <mat-toolbar color="primary" class="mat-elevation-z4">
    <button mat-icon-button routerLink="/home">
      <mat-icon>home</mat-icon>
    </button>
    <div fxFlex></div>
    <span>Ants Coding Challenge</span>
    <div fxFlex></div>
    <cci-theme-selector></cci-theme-selector>
  </mat-toolbar>

  <form #form #loginForm="ngForm" fxFlex fxLayoutAlign="center center">
    <mat-card appearance="outlined" class="login__card">
      <mat-card-title>Login</mat-card-title>
      <mat-card-content>

          <mat-form-field class="login__username">
            <input #usernameInput matInput name="username" maxlength="40" placeholder="Username" required (keydown.enter)="login()"
              [(ngModel)]="username" autocomplete="off">
              <mat-hint>
                No password is required. Remember this username to be able to edit bugs created as this username.
              </mat-hint>
            <mat-error *ngIf="loginForm.controls.username?.errors?.required">
              Username is required
            </mat-error>
          </mat-form-field>

      </mat-card-content>
      <mat-card-actions align="center">
        <button mat-raised-button color="primary" [disabled]="loginForm.invalid" (click)="login()" type="button">Login</button>
      </mat-card-actions>
    </mat-card>
  </form>

</div>


