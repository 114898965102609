

<div fxFlexFill fxLayout="column">
  <mat-toolbar color="primary" class="edit-ants__toolbar mat-elevation-z4">
    <button mat-icon-button routerLink="/home">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div fxFlex></div>
    <span>Ants Coding Challenge</span>
    <div fxFlex></div>
    <cci-theme-selector></cci-theme-selector>
  </mat-toolbar>


  <div class="game" fxFlex *ngIf="board$ | async as board else loadingTemplate" fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="start center">
    <cci-ant-grid
      [animals]="board.animals"
      [elapsedTicks]="board.gameStatus.elapsedTicks"
      [gameLength]="board.gameStatus.gameLength"
      [grid]="board.grid"
      [fullWidth]="true">
    </cci-ant-grid>
    <cci-leaderboard [animals]="board.animals"></cci-leaderboard>
  </div>

</div>

<ng-template #loadingTemplate>
  <div class="game" fxFlex fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
